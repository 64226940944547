export function updateLog(data) {
  all_logs[data.id] = createLog(data);
}

export function removeTemporaryLogIcons() {
  temporaryMarkers.clearLayers();
  return oplogMarkers.getLayers().map(function (layer) {
    if (layer.options.icon.options.className === "temporaryMapIcon") {
      oplogMarkers.removeLayer(layer);
    }
  });
}

export function changeToMeters(value, unit) {
  let meters = value;
  switch (unit) {
    case "m":
      meters = value;
      break;
    case "km":
      meters = value * 1000;
      break;
    case "ft":
      meters = value / 3.68;
      break;
    case "mi":
      meters = value * 1609.34;
      break;
    case "nmi":
      meters = value * 1852;
      break;
    case "yard":
      meters = value / 1.094;
      break;
  }
  return meters;
}
export var oplogMarkerArray = [];

export function createLog(log) {
  var marker;
  // see github issue #1604
  if (log.data && Array.isArray(log.data)) {
    log = log.data[0];
  }
  log.task = log.task ? log.task : log.data ? log.data.task_j : null;
  log.user = log.user ? log.user : log.data ? log.data.user_j : null;
  log.marker_type = log.marker_type
    ? log.marker_type
    : log.data
    ? log.data.marker_type_j
    : null;
  // delete log.data;

  var logObject = {
    log: log,
    updateMarker: function (options) {
      if (oplogMarkers === undefined) {
        return;
      } else {
        if (log.decimal_lat && log.decimal_lng && log.show_on_map) {
          var markerOptions = {
            className: log.id + " icon-log",
            log_id: log.id,
            iconAnchor: [10, 10],
            iconSize: [20, 20],
            popupAnchor: [3, -10],
          };
          if (log.marker_type && log.marker_type.marker) {
            markerOptions.html = `<i style="color: ${log.marker_type.color}" class='fa fa-lg fa-fw fa-${log.marker_type.marker}'/>`;
          } else {
            markerOptions.html = "<i class='fa fa-lg fa-fw fa-map-pin'/>";
          }
          var icon = new L.divIcon(markerOptions);

          var latlng = L.latLng([log.decimal_lat, log.decimal_lng]);
          var popup =
            options && options.frontpage_map
              ? HandlebarsTemplates["map/marker_popup_frontpage"](log)
              : log.text;

          marker = L.marker(latlng, {
            icon: icon,
          });

          if (log.tooltip) {
            var tooltip = L.tooltip({
              permanent: true,
              position: "top",
              className: "custom-leaflet-tooltip break-text",
            });
            tooltip.setContent(log.text);
            marker.bindTooltip(tooltip).openTooltip();
          } else {
            marker.bindPopup(popup);
          }

          if (options && options.insert) {
            oplogMarkerArray.push(marker);
            if (log.data && log.data.radius && log.data.radius.value) {
              let radiusSize = changeToMeters(
                log.data.radius.value,
                log.data.radius.unit
              );
              let radiusColor = log.data.radius.radius_color;
              L.circle(latlng, {
                radius: radiusSize,
                color: radiusColor,
                fillColor: radiusColor,
              }).addTo(oplogCircleMarkers);
            }
          }
          var marker_exists = false;
          oplogMarkers.getLayers().map(function (marker) {
            if (marker.options.icon.options.log_id == log.id) {
              marker.setLatLng(latlng);
              marker.setIcon(icon);
              marker_exists = true;
            }
          });
          if (!marker_exists) {
            oplogMarkers.addLayer(marker);
          }
          oplogMarkers.refreshClusters();
          all_logs_coordinates[log.id].updateMarkerColor();
        }
        return marker;
      }
    },
    updateMarkerColor: function () {
      if (log.marker_type && log.marker_type.color) {
        $(".icon-log." + log.id).css("color", log.marker_type.color);
      }
    },
    updateRow: function (options = { prepend: false }) {
      log.current_tenant = _current_tenant ? _current_tenant : {};
      var html = HandlebarsTemplates["operation_logs/single_oplog_row"]({
        operation_logs: [log],
        display_operation_log_tags: display_tags["operation_logs"],
        current_tenant: _current_tenant,
      });
      var elemid = "#log-" + log.id;
      if (document.querySelectorAll(elemid).length === 0) {
        if (options.prepend) {
          $("#operationloglist").prepend(html);
        } else {
          $("#operationloglist").append(html);
        }
      } else {
        $(elemid).replaceWith(html);
      }
    },
    getMoreData: function (options) {
      $.ajax({
        url: "/operation_logs/" + log.id,
        dataType: "json",
        type: "GET",
        success: function (data) {
          all_logs_coordinates[data.id] = createLog(data);
          all_logs_coordinates[data.id].updateMarker();
        },
      });
    },
    deleteLog: function () {
      swal({
        title: I18n.t("helpers.delete_question_mark"),
        text: I18n.t("operation.oplog.are_you_sure_delete_operation_log"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5cb85c",
        confirmButtonText: I18n.t("helpers.delete"),
        cancelButtonText: I18n.t("helpers.cancel"),
      }).then(function (e) {
        if (e.value === true) {
          $.ajax({
            type: "POST",
            url: "/operation_logs/" + log.id + "/destroy",
            data: {
              operation_log_id: log.id,
            },
            dataType: "json",
            success: function (data) {
              toastr.success("Atviki eytt");
              $("#log-" + log.id).each(function () {
                this.remove();
              });
              $(".log-" + log.id).each(function () {
                this.remove();
              });
              $(".icon-log." + log.id).each(function () {
                this.remove();
              });
              $("#eventCollapse").empty();
              $("#eventCollapse").toggleClass("d-none");
              $("#oplogTableBody").removeClass("d-none");
              $("#oplogPanelFooter").toggleClass("d-none");
              updateLog(data);
            },
          });
        }
      });
    },
    getEditForm: function () {
      $.ajax({
        url: "/operation_logs/" + log.id + "/edit",
        dataType: "html",
        data: {
          id: log.id,
        },
        success: function (data) {
          $("#eventCollapse").empty();
          $("#eventCollapse").html(data);
          $("#eventCollapse").toggleClass("d-none");
          $("#oplogTableBody").toggleClass("d-none");
          $("#oplogPanelFooter").toggleClass("d-none");
          $("#eventDestroyButton").removeClass("d-none").attr("name", log.id);
          $("#get_log_coords_on_map").attr("name", log.id);
          $("#operation_log_pointtype").addClass("form-control c-select");
          // highlight logs linked to the task
          $("#tasklist").children("tr").removeClass("warning");
          $("#task-" + log.task_id).addClass("warning");

          // make a ajax call to get the oplog object - should switch out response for a json object so not to make 2 calls
          if (log.lat && log.lng) {
            // once we have the oplog info zoom the map to the location
            var lat = parseWSG84(log.lat);
            var lng = parseWSG84(log.lng);
            if (lat && lng) {
              map.setView([lat, lng], 16);
              all_logs[log.id].updateMarker();
              $(".leaflet-marker-icon").removeClass("halo");
              $(".leaflet-marker-pane")
                .children("." + log.id)
                .addClass("halo");
              if (log.data && log.data.radius && log.data.radius.value) {
                let radiusSize = changeToMeters(
                  log.data.radius.value,
                  log.data.radius.unit
                );
                L.circle([lat, lng], {
                  radius: radiusSize,
                }).addTo(temporaryMarkers);
              }
            }
          }
        },
        error: function () {},
      });
    },
  };
  return logObject;
}
