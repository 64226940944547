export function deactivateResourceInGroup(resource_id, group_id, callback) {
  $.ajax({
    url: `/groups/${group_id}/deactivate_resource.json`,
    method: "POST",
    dataType: "json",
    data: {
      resource_id
    },
    success: function(data) {
      callback(data);
    }
  });
}
export function reactivateResourceInGroup(resource_id, group_id, callback) {
  $.ajax({
    url: `/groups/${group_id}/reactivate_resource.json`,
    method: "POST",
    dataType: "json",
    data: {
      resource_id
    },
    success: function(data) {
      callback(data);
    }
  });
}

export function updateGroupTaskStatusToDone(id) {
  $("#group-task-finish-" + id).attr("disabled", true);
  $("#group-task-finish-" + id)
    .removeClass("badge-success")
    .addClass("badge-danger");
  if ($(this).attr("disabled")) {
    return;
  }
  $.ajax({
    url: "/tasks/" + id,
    dataType: "json",
    type: "PUT",
    async: true,
    data: {
      task: {
        task_id: id,
        done: true
      }
    },
    success: function(data) {
      TaskJs.updateTask(data);
      if (data.done) {
        //if the task is done
        toastr.success("Verkefni lokið");
        $("#group-task-finish-" + data.id)
          .text("Lokið")
          .attr("disabled", true);
        $("#tasks option[value=" + data.id + "]").attr("selected", false);
      }
    }
  });
}

export function addTaskButtonClick(object) {
  $("#group_change_associated_tasks").prop("checked", true);
  // find the id and name of the clicked buttons task

  var id = $(object)
    .closest("tr")
    .attr("id");
  var taskobject = all_tasks[id.split("task-")[1]].task;
  var taskname = taskobject.name;

  if ($("#tasks option[value=" + id.split("task-")[1] + "]").length === 0) {
    $("#tasks").append(
      '<option value="' +
        id.split("task-")[1] +
        '" selected>' +
        taskname +
        "</option>"
    );
  }

  $("#tasks option[value=" + id.split("task-")[1] + "]").prop("selected", true);

  if ($("#" + id).length > 0) {
    //$('#group_task_table_body').append('<tr class="new-task" id="' + id + '"><td>' + taskname + '</td><td><i class="fa fa-trash float-right groups-task-remove" name="' + id.split('task-')[1] + '" onclick="removeTaskFromGroup(this)></i></td></tr>');
    if ($("#group_task_table_body").children("#" + id).length === 0) {
      var html = HandlebarsTemplates["groups/group_task_list_row"](taskobject);
      $("#group_task_table_body").append(html);
    }
    $("#group_task_table_body")
      .children("#" + id)
      .show();
  }
  //add that task to our group with a class of new-task so we can remove it if needed

  // hide the task and make it selectable later on
  $(object)
    .closest("tr")
    .addClass("d-none");
}

export function removeTaskFromGroup(object) {
  $("#group_change_associated_tasks").prop("checked", true);
  $("#tasks option[value=" + $(object).attr("name") + "]").prop(
    "selected",
    false
  );
  $(object)
    .parent()
    .parent()
    .hide();
  var taskListRow = $("#tasklist").children("#task-" + $(object).attr("name"));
  //taskListRow.children(":nth-child(6)").append('<button class="btn btn-sm btn-primary float-right leftmargin-10 task-add-button" onclick="addTaskButtonClick(this)"><i class="fa fa-plus"></i></button>');
  taskListRow.show();
}

export function updateGroup(data) {
  all_groups[data.id] = createGroup(data);
}

export function createGroup(group) {
  group.tasks = group.tasks
    ? group.tasks
    : group.data
    ? group.data.tasks_j
    : null;
  group.resources = group.resources
    ? group.resources
    : group.data
    ? group.data.resources_j
    : null;
  // delete group.data;

  var groupObject = {
    group: group,
    updateRow: function(options = { prepend: false }) {
      group.current_tenant = _current_tenant;
      var html = HandlebarsTemplates["groups/single_group_row"]({
        groups: [group],
        display_group_tags: display_tags["groups"]
      });
      var elemid = "#group-" + group.id;
      if (document.querySelectorAll(elemid).length === 0) {
        if (options.prepend) {
          $("#grouplist").prepend(html);
        } else {
          $("#grouplist").append(html);
        }
      } else {
        $(elemid).replaceWith(html);
      }
    },
    getEditForm: function() {
      $.ajax({
        url: "/groups/" + group.id + "/edit",
        dataType: "html",
        data: {
          group_id: group.id
        },
        success: function(data) {
          if (sessionStorage.getItem("addingGroups") === "true") {
            return;
          }
          $("#groupsCollapse").empty();
          $("#groupDestroyButton")
            .removeClass("d-none")
            .attr("name", group.id);
          $("#groupsCollapse").html(data);
          $("#groupsCollapse").toggleClass("d-none");
          $("#groupsPanelBody").toggleClass("d-none");
          $("#tasklist")
            .children("tr")
            .removeClass("success")
            .removeClass("warning");
          sessionStorage.setItem("addingGroups", "false");
          sessionStorage.setItem("addingTasks", "false");
          let taskIds = $("#tasks").val();
          if (taskIds && taskIds.length !== 0) {
            taskIds.map(function(a) {
              return $("#tasklist")
                .children("#task-" + a)
                .addClass("warning");
            });
            let elem = document.getElementById("task-" + taskIds[0]);
            return elem && elem.scrollIntoView();
          }
        },
        error: function() {}
      });
    }
  };
  return groupObject;
}
