function letterToNato(letter) {
    switch (letter.toLowerCase()) {
        case 'a':
            return 'Alpha';
        case 'b':
            return 'Bravo';
        case 'c':
            return 'Charlie';
        case 'd':
            return 'Delta';
        case 'e':
            return 'Echo';
        case 'f':
            return 'Foxtrot';
        case 'g':
            return 'Golf';
        case 'h':
            return 'Hotel';
        case 'i':
            return 'India';
        case 'j':
            return 'Juliet';
        case 'k':
            return 'Kilo';
        case 'l':
            return 'Lima';
        case 'm':
            return 'Mike';
        case 'n':
            return 'November';
        case 'o':
            return 'Oscar';
        case 'p':
            return 'Papa';
        case 'q':
            return 'Quebec';
        case 'r':
            return 'Romeo';
        case 's':
            return 'Sierra';
        case 't':
            return 'Tango';
        case 'u':
            return 'Uniform';
        case 'v':
            return 'Victor';
        case 'w':
            return 'Whiskey';
        case 'x':
            return 'Xray';
        case 'y':
            return 'Yankee';
        case 'z':
            return 'Zulu';
        case ',':
            return 'Comma';
        case '.':
            return 'FullStop';
        case '!':
            return 'ExclamationMark';
        case '?':
            return 'QuestionMark';
        case '@':
            return 'AtSymbol';
        default:
            return '';
    }
}

export function phraseToNato(phrase) {
    if (phrase) {
        const words = phrase.split(' ');
        let output = [];
        words.map(word => {
            output = output.concat(word.split('').map(letter => letterToNato(letter)))
            output.push('-')
        })
        return output.join(' ')
    }
}

