export function createOperation(op) {
  op.kind = op.kind;
  op.priority = op.priority;
  op.risks = op.risks ? op.risks : op.data.risks_j;
  op.area = op.area;
  op.category = op.category;
  op.shared_operation_tenants = op.shared_operation_tenants
    ? op.shared_operation_tenants
    : op.data.shared_operation_tenants_j;
  op.operation_scale = op.operation_scale;
  // delete op.data;
  var opObject = {
    op: op,
    updateRow: function() {
      op.token = $('meta[name="csrf-token"]').attr("content");
      var html = HandlebarsTemplates["operations/op_list"]({
        operations: [op],
        tenant: _current_tenant,
        sar_tenant: _current_tenant.tenant_type.short_name === "sar",
        energy_tenant: _current_tenant.tenant_type.short_name === "energy",
        redcross_tenant: _current_tenant.tenant_type.short_name === "redcross",
        client_isadmin: _client_isadmin,
        client_ismanager: _client_ismanager
      });
      var elemid = "tr.operation_row#" + op.id;
      if (document.querySelectorAll(elemid).length === 0) {
        $("#operationlist").prepend(html);
      } else {
        $(elemid).replaceWith(html);
      }
    }
  };
  return opObject;
}
