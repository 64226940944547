function taskStateColor(task) {
  return task.groups && task.groups.length > 0 ?
    "#fafe68" :
    task.search_area && task.search_area.searched ?
    "#40b350" :
    "#fc0d1b";
}

export function updateTask(data) {
  all_tasks[data.id] = createTask(data);
}

export function removeTemporaryTaskIcons() {
  return taskMarkers.getLayers().map(function(layer) {
    if (layer.options.icon.options.className === "temporaryMapIcon") {
      taskMarkers.removeLayer(layer);
    }
  });
}
export var taskMarkerArray = [];

export function createTask(task) {
  // see github issue #1604
  if (task.data && Array.isArray(task.data)) {
    task = task.data[0];
  }
  var marker;
  task.search_area = task.search_area ?
    task.search_area :
    task.data ?
    task.data.search_area_j :
    null;
  task.groups = task.groups ?
    task.groups :
    task.data ?
    task.data.groups_j :
    null;
  task.operation_logs = task.operation_logs ?
    task.operation_logs :
    task.data ?
    task.data.operation_logs_j :
    null;
  task.user = task.user ? task.user : task.data ? task.data.user_j : null;
  // delete task.data;
  var taskObject = {
    task: task,
    updateRow: function(options = {
      prepend: false
    }) {
      task.current_tenant = _current_tenant ? _current_tenant : {};
      var html = HandlebarsTemplates["tasks/single_task_row"]({
        tasks: [task],
        display_task_tags: display_tags["tasks"],
        display_task_comments: display_comments["tasks"],
        current_tenant: _current_tenant
      });
      var elemid = "#task-" + task.id;
      if (document.querySelectorAll(elemid).length === 0) {
        if (options.prepend) {
          $("#tasklist").prepend(html);
        } else {
          $("#tasklist").append(html);
        }
      } else {
        $(elemid).replaceWith(html);
      }
    },
    updateKanbanPosition: function() {
      var source = $("#task-kanban-panel").html();
      var html = "";
      if (source) {
        var template = Handlebars.compile(source);
        html = template(task);
      }
      var elem = $("#task-panel-" + task.id);
      if (elem.length !== 0) {
        elem.remove();
      }
      if (task.done) {
        $("#tasks_done").prepend(html);
      } else if (task.has_groups) {
        $("#tasks_in_progress").prepend(html);
      } else {
        $("#tasks_todo").prepend(html);
      }
      var todocount = 0;
      var doingcount = 0;
      var donecount = 0;
      all_tasks.map(function(task) {
        if (task.task.done && !task.task.has_groups) {
          donecount++;
        } else if (!task.task.done && task.task.has_groups) {
          doingcount++;
        } else {
          todocount++;
        }
      });
      $("#kanban-todo-count").html("(" + todocount + ")");
      $("#kanban-done-count").html("(" + donecount + ")");
      $("#kanban-doing-count").html("(" + doingcount + ")");
    },
    updateArea: function(options = {}) {
      if (hide_finished_tasks && task.done) {
        return;
      }
      var search_area = task.search_area;
      var classname = "search_area_path task-" + task.id;
      var points = [];
      var zone;

      if (
        task.search_area_id &&
        task.search_area &&
        typeof mapAreas !== "undefined"
      ) {
        if (
          task.decimal_lat &&
          task.decimal_lat !== "" &&
          typeof task.decimal_lat !== "undefined" &&
          task.decimal_lng &&
          task.decimal_lng !== "" &&
          typeof task.decimal_lng !== "undefined" &&
          typeof taskMarkers !== "undefined"
        ) {
          var marker = L.marker([task.decimal_lat, task.decimal_lng], {
            opacity: 0,
            iconAnchor: [0, 10],
            iconSize: [20, 20],
            popupAnchor: [3, -10]
          })
          var tooltip = L.tooltip({
            permanent: true,
            direction: "top",
            className: "",
            offset: L.point([-7,15])
          });
          tooltip.setContent(search_area.name);
          marker.bindTooltip(tooltip).openTooltip();

          mapAreaTooltips.addLayer(marker)
        }
        var json = search_area.json;
        if (json && json.geoJSON) {
          points = json.geoJSON.geometry.coordinates;
        }
        $(".search_area_path.task-" + task.id).remove();
        if (search_area.area_size == "0") {
          zone = new L.Polyline(points, {
            dashArray: "10, 11",
            lineJoin: "miter",
            weight: 5,
            color: taskStateColor(task),
            opacity: 0.9,
            className: classname,
            taskId: task.id
          });
          if (options.edit) {
            map.fitBounds(zone.getBounds());
            editableLayers.clearLayers();
            editableLayers.addLayer(zone);
          } else {
            mapPaths.addLayer(zone);
          }
          if (points) {
            var icon = new L.divIcon({
              className: `leaflet-div-icon-circle ${
                task.groups && task.groups.length > 0
                  ? "leaflet-div-icon-circle-active"
                  : search_area.searched
                  ? "leaflet-div-icon-circle-done"
                  : "leaflet-div-icon-circle-unassigned"
              }`,
              iconAnchor: [5, 5],
              iconSize: [10, 10]
            });
            var pathStartMarker = L.marker(points[0], {
              icon: icon
            });
            var pathEndMarker = L.marker(points[points.length - 1], {
              icon: icon
            });
            pathStartMarker.addTo(mapPaths);
            pathEndMarker.addTo(mapPaths);
          }
        } else {
          zone = new L.Polygon(points, {
            fillColor: taskStateColor(task),
            fillOpacity: 0.4,
            weight: 2,
            color: "Black",
            opacity: 0.5,
            className: classname
          });
          if (options.edit) {
            map.fitBounds(zone.getBounds());
            editableLayers.clearLayers();
            editableLayers.addLayer(zone);
          } else {
            mapAreas.addLayer(zone);
          }
        }
      }
    },
    getMoreData: function(options) {
      $.ajax({
        url: "/tasks/" + task.id,
        dataType: "json",
        type: "GET",
        data: {
          id: task.id
        },
        success: function(data) {
          all_tasks_coordinates[data.id] = createTask(data);
          if (!options.update_marker === false) {
            all_tasks_coordinates[data.id].updateMarker();
          }
        }
      });
    },
    updateMarker: function(options) {
      if (hide_finished_tasks && task.done) {
        return;
      }
      if (
        task.decimal_lat &&
        task.decimal_lat !== "" &&
        typeof task.decimal_lat !== "undefined" &&
        task.decimal_lng &&
        task.decimal_lng !== "" &&
        typeof task.decimal_lng !== "undefined" &&
        typeof taskMarkers !== "undefined"
      ) {
        var markerOptions = {
          className: `task-${task.id}`,
          task_id: task.id,
          iconAnchor: [0, 10],
          iconSize: [20, 20],
          popupAnchor: [3, -10]
        };
        if (task.done) {
          markerOptions.className += " icon-task-done icon-task";
        } else if (task.has_groups) {
          markerOptions.className += " icon-task-active icon-task";
        } else {
          markerOptions.className += " icon-task-unassigned icon-task";
        }
        if (task.data.marker_type_j && task.data.marker_type_j.marker) {
          markerOptions.html = `<i class='fa fa-lg fa-fw fa-${task.data.marker_type_j.marker}'/>`;
        } else {
          markerOptions.html =
            "<i class='fa fa-lg fa-fw fa-exclamation-triangle'/>";
        }
        var icon = new L.divIcon(markerOptions);
        var popuptemplate =
          options && options.frontpage_map ?
          "map/marker_popup_frontpage" :
          "map/marker_popup";
        var popuptext = HandlebarsTemplates[popuptemplate](task);
        var latlng = L.latLng([task.decimal_lat, task.decimal_lng]);
        marker = L.marker(latlng, {
          icon: icon
        }).bindPopup(popuptext);

        if (options && options.insert) {
          taskMarkerArray.push(marker);
        }
        var marker_exists = false;
        taskMarkers.getLayers().map(function(marker) {
          if (marker.options.icon.options.task_id == task.id) {
            marker.setLatLng(latlng);
            marker.setIcon(icon);
            marker.bindPopup(popuptext);
            marker_exists = true;
            taskMarkers.refreshClusters();
          }
        });
        if (!marker_exists) {
          taskMarkers.addLayer(marker);
          taskMarkers.refreshClusters();
        }
      }
    },
    updateStatus: function(options) {
      $.ajax({
        url: "/tasks/" + task.id,
        dataType: "json",
        type: "PUT",
        data: {
          task: {
            task_id: task.id,
            done: options.done,
            active: options.active
          }
        },
        success: function(data) {
          if (typeof map !== "undefined") {
            map.closePopup();
          }
          var task = createTask(data);
          all_tasks[data.id] = task;
          all_tasks[data.id].updateRow();
          all_tasks[data.id].updateMarker();
          if (task.task.done) {
            toastr.success("Successfully finished", "Task");
          } else {
            toastr.success("Successfully reactivated", "Task");
          }
        }
      });
    },
    updatePriority: function(direction) {
      // here we could just update the task and the row and if
      // there is an error the data reload should handle it
      $.ajax({
        url: "/update_priority",
        method: "POST",
        dataType: "json",
        data: {
          task_id: task.id,
          upOrDown: direction.toString()
        },
        success: function(data) {
          $("#task-priority-" + data.id).text(data.new_priority.name);
          $("#priority-up-" + data.id).css("visibility", "hidden");
          $("#priority-down-" + data.id).css("visibility", "hidden");
          //alert(data.down);
          if (data.up) {
            $("#priority-up-" + data.id).css("visibility", "visible");
          }
          if (data.down) {
            $("#priority-down-" + data.id).css("visibility", "visible");
          }
        }
      });
    },

    highlightGroups: function() {
      // highlight groups that are working on this task
      $("#grouplist")
        .children("tr")
        .removeClass("success")
        .removeClass("warning");
      task.groups.map(function(group) {
        $("#group-" + group.id.toString()).addClass("warning");
      });
    },
    deleteTask: function() {
      swal({
        title: I18n.t("helpers.delete_question_mark"),
        text: I18n.t("operation.task.are_you_sure_delete_task"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5cb85c",
        confirmButtonText: I18n.t("helpers.delete"),
        cancelButtonText: I18n.t("helpers.cancel")
      }).then(function(e) {
        if (e.value === true) {
          $.ajax({
            type: "POST",
            url: "/tasks/" + task.id + "/delete",
            data: {
              task_id: task.id
            },
            dataType: "json",
            success: function(data) {
              toastr.success("Verkefni eytt");
              $("#task-" + task.id).each(function() {
                this.remove();
              });
              $(".task-" + task.id).each(function() {
                this.remove();
              });
              $(".icon-task." + task.id).each(function() {
                this.remove();
              });
              $("#tasksCollapse").empty();
              $("#tasksCollapse").toggleClass("d-none");
              $("#tasksPanelBody").toggleClass("d-none");
              updateTask(data);
            }
          });
        }
      });
    },
    getEditForm: function() {
      var addingTasks = sessionStorage.getItem("addingTasks");
      if (addingTasks !== "true") {
        $.ajax({
          url: "/tasks/" + task.id + "/edit",
          dataType: "html",
          data: {
            operation_id: task.operation_id
          },
          success: function(data) {
            if (typeof map !== "undefined") {
              map.closePopup();
            }
            $("#tasksCollapse").empty();
            $("#tasksCollapse").html(data);
            $("#tasksCollapse").toggleClass("d-none");
            $("#tasksPanelBody").toggleClass("d-none");
            $("#taskDestroyButton")
              .removeClass("d-none")
              .attr("name", task.id);
            $("#task-loading-veil").remove();
            $("#task-card-body").removeClass("d-none");
            // highlight logs linked to the task
            $("#operationloglist")
              .children("tr")
              .removeClass("warning");
            if (task.operation_logs) {
              task.operation_logs.map(function(log) {
                $("#log-" + log.id).addClass("warning");
              });
            }
            // zoom the map on the task
            if (task.decimal_lat && task.decimal_lng) {
              var lat = task.decimal_lat;
              var lng = task.decimal_lng;
              if (typeof map !== "undefined") {
                map.setView([lat, lng], 16);
              }
              $(".leaflet-marker-icon").removeClass("halo");
              $(".leaflet-marker-pane")
                .children("." + task.id)
                .addClass("halo");
            }
            // check the status of the task and change html accordingly
            $(
              "#task-done, #task-unassigned, #get_coords_on_map",
              "#task_sms"
            ).attr("name", task.id);
            if (task.done) {
              $("#task-done")
                .removeClass("btn-outline-secondary")
                .addClass("btn-primary");
              $("#task-unassigned")
                .removeClass("btn-primary")
                .addClass("btn-outline-secondary");
              $("#task-unassigned").text(
                I18n.t("operation.task.index.in_progress")
              );
              $("#task-done").text(I18n.t("operation.task.finished"));
              $(".task-" + task.id)
                .attr("fill", "green")
                .attr("stroke", "black");
            } else if (task.has_groups) {
              $("#task-done")
                .removeClass("btn-primary")
                .addClass("btn-outline-secondary");
              $(".task-" + task.id)
                .attr("fill", "yellow")
                .attr("stroke", "black");

              $("#task-unassigned-group").hide();
            } else {
              $("#task-unassigned")
                .removeClass("btn-outline-secondary")
                .addClass("btn-primary");
              $("#task-done")
                .removeClass("btn-primary")
                .addClass("btn-outline-secondary");
              $("#task-inprogress-group").hide();
            }
            // change the search_area value in search areas
            var search_area_id = task.search_area_id;
            $("#search_area").val(search_area_id);
            if (search_area_id != "0" && search_area_id !== null) {
              all_tasks_coordinates[task.id].updateArea({
                edit: true,
                insert: false
              });
              return $(".change_area").prop("disabled", false);
            } else {
              return $(".change_area").prop("disabled", true);
            }
          },
          error: function(error) {
            console.error(error);
          }
        });
      }
    }
  };
  return taskObject;
}
