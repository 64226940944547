var flatten = function(ob) {
  var toReturn = {};
  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == "object") {
      var flatObject = flatten(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export function uploadCSV(url, operation_id, reload = false) {
  swal({
    title: "Upload CSV File",
    type: "info",
    input: "file",
    inputAttributes: {
      accept: ".csv, text/csv",
      "aria-label": "Upload your csv file"
    },
    showCancelButton: true,
    confirmButtonColor: "#5cb85c",
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel"
  }).then(function(file) {
    Papa.parse(file.value, {
      header: true,
      complete: function(results) {
        $.ajax({
          url,
          method: "POST",
          dataType: "json",
          data: {
            objects: results.data,
            operation_id
          },
          success: function(data) {
            if (data.error) {
              alert(
                `${data.error}. Some tasks might have not have been saved, please take a look at your CSV file for errors`
              );
            } else if (data.success) {
              toastr.success("Success");
              if (reload){
                window.location.reload()
              }
              
            }
          }
        });
      }
    });
  });
}

export function saveAsCsv(data, name = "", delimiter = ";", columns = null) {
  let title = name
    ? "Save " + _.startCase(name.split("_").join(" ")) + " CSV?"
    : "Save CSV file?";
  swal({
    title,
    type: "info",
    showCancelButton: true,
    confirmButtonColor: "#5cb85c",
    confirmButtonText: "Save",
    cancelButtonText: "Cancel"
  }).then(function() {
    let columns = []
    let flattenedData = data.map(function(a) {
      if (a.custom_values) {
        a.custom_values = _.chain(a.custom_values)
          .mapKeys(function(value, key) {
            return value ? (value.label ? value.label : key) : key;
          })
          .mapValues(function(value) {
            if (value && value.value_label) {
              return value.value_label;
            } else if (value && value.value) {
              return value.value;
            } else {
              return "";
            }
          })
          .value();
      }
      return flatten(a);
    })
    flattenedData.map(function(a) {
      Object.keys(a).map(function(key){
        if(columns.indexOf(key) === -1) {
          columns.push(key);
        }
      })
    })
    var csv = Papa.unparse(
      flattenedData,
      {
        header: true,
        delimiter,
        columns
      }
    );
    var blob = new Blob(["\ufeff", csv], {
      type: "data:text/csv;charset=utf-8"
    });
    var filename = "csv_export_" + name + "_" + moment().format("DD.MM.YYYY");
    FileSaver.saveAs(blob, filename + ".csv");
  });
}
