// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.
//
// WARNING: THE FIRST BLANK LINE MARKS THE END OF WHAT'S TO BE PROCESSED, ANY BLANK LINE SHOULD
// GO AFTER THE REQUIRES BELOW.
//
//= require action_cable
//= require_self
//= require cable
//= require papaparse/papaparse.min
//= require sweetalert2
//= require jquery3
//= require jquery_ujs
//= require popper
//= require jquery-tablesorter/jquery.tablesorter
//= require jquery-tablesorter/extras/jquery.metadata
//= require bootstrap-sprockets
//= require handlebars.runtime
//= require_tree ./templates
//= require moment/moment
//= require moment/locale/is
//= require moment/locale/ka
//= require moment/locale/de
//= require moment/locale/bg
//= require moment/locale/es
//= require lodash/lodash
//= require file-saver/FileSaver
//= require csv
//= require i18n/translations
//= require fetch
//= require toastr/toastr
//= require select2-full
//= require select2_locale_is
//= require is
//= require leaflet/dist/leaflet
//= require leaflet-draw/dist/leaflet.draw
//= require leaflet.markercluster/dist/leaflet.markercluster
//= require leaflet-easybutton/src/easy-button
//= require leaflet.contextmenu
//= require leaflet.utm
//= require turf.min
//= require operations
//= require groups
//= require logs
//= require tasks
//= require risks
//= require cocoon
//= require later/later
//= require leaflet.wms/dist/leaflet.wms
//= require jquery.remotipart
//= require chart.js/dist/Chart
//= require color-scheme/lib/color-scheme
//= require_tree ./laptop
export function coordinatesText(latlng, tenant) {
  var x = latlng.lat;
  var y = latlng.lng;
  var x_dadm = "";
  var y_dadm = "";
  var x_utm = "";
  var y_utm = "";
  var zone_utm = "";
  var x_dadm = parseReverseWSG84(latlng.lat);
  var y_dadm = parseReverseWSG84(latlng.lng);
  var utm_values = latlng.utm();
  var x_utm = parseInt(utm_values.x);
  var y_utm = parseInt(utm_values.y);
  var zone_utm = utm_values.zone + utm_values.band;
  switch (tenant.settings.default_coordinates_format) {
    case "dadm":
      return "<b>N:</b> " + x_dadm + " <b>W:</b> " + y_dadm;
      break;
    case "utm":
      return x_utm + ", " + y_utm + ", " + zone_utm;
      break;
    default:
      return "<b>N:</b> " + x + " <b>W:</b> " + y;
      break;
  }
}

function formatWatchResults(watch) {
  if (watch.name === undefined) {
    return;
  }
  return `<div class="select2-result-container ">
    <p class="nomargin">
      <span>${watch.name}</span>
      <small>${watch.tenant ? watch.tenant.name : ""}</small>
    </p>
  </div>`;
}

function formatWatchSelection(watch) {
  return watch.name || watch.text;
}

function formatTriageResults(triage) {
  if (triage.name === undefined) {
    return;
  }
  return `<div class="select2-result-container ">
    <p class="nomargin">
      ${triage.name ? "<span>" + triage.name + "</span>" : ""}
      <span>(${triage.barcode})</span>
    </p>
  </div>`;
}

export function watchesSelect2Config(
  operation_id = undefined,
  include_third_party = undefined,
  only_type = undefined
) {
  return {
    ...commonSelect2Config(),
    templateResult: formatWatchResults,
    templateSelection: formatWatchSelection,
    allowClear: true,
    language: I18n.locale.split("_")[0],
    placeholder: I18n.t("application.please_select"),
    ajax: {
      url: "/watches.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term, // search term / user input
        };
      },
      processResults: function (data, params) {
        return {
          results: data,
        };
      },
      cache: true,
    },
    minimumInputLength: 2,
    escapeMarkup: function (markup) {
      return markup;
    },
  };
}

export function commonSelect2Config() {
  return {
    theme: "bootstrap4",
    placeholder: I18n.t("application.please_select_or_search"),
  };
}

export function operationsSelect2Config() {
  return {
    theme: "bootstrap4",
    allowClear: true,
    multiple: true,
    language: I18n.locale.split("_")[0],
    placeholder: I18n.t("application.please_select"),
    ajax: {
      url: "/all_operations.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          filter_operations: params.term, // search term / user input
        };
      },
      processResults: function (data, params) {
        return {
          results: data.operations,
        };
      },
      cache: true,
    },
    templateResult: formatWatchResults,
    templateSelection: formatWatchSelection,
    escapeMarkup: function (markup) {
      return markup;
    },
  };
}
export function deviceIdCodeSelect2Config() {
  return {
    theme: "bootstrap4",
    allowClear: true,
    multiple: true,
    language: I18n.locale.split("_")[0],
    placeholder: I18n.t("application.please_select"),
    ajax: {
      url: "/device_identification_codes.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          search: params.term, // search term / user input
        };
      },
      processResults: function (data, params) {
        return {
          results: data,
        };
      },
      cache: true,
    },
    templateResult: formatWatchResults,
    templateSelection: formatWatchSelection,
    escapeMarkup: function (markup) {
      return markup;
    },
  };
}

export function triagesSelect2Config() {
  return {
    theme: "bootstrap4",
    allowClear: true,
    language: I18n.locale.split("_")[0],
    placeholder: I18n.t("application.please_select"),
    ajax: {
      url: "/triages.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term, // search term / user input
        };
      },
      processResults: function (data, params) {
        return {
          results: data.data,
        };
      },
      cache: true,
    },
    templateResult: formatTriageResults,
    templateSelection: formatWatchSelection,
    escapeMarkup: function (markup) {
      return markup;
    },
  };
}

export function resourcesSelect2Config(
  operation_id = undefined,
  include_third_party = undefined,
  only_type = undefined,
  include_unassignable = undefined
) {
  let opts = {
    ...commonSelect2Config(),
    allowClear: true,
    language: I18n.locale.split("_")[0],
    ajax: {
      url: "/resources_json.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        var area_id = $("#group_region").val(); // filtering the search term by region
        var unit_id = $("#group_unit").val(); // filtering the search term by unit
        var group_id = group_id;
        var op_id = operation_id;
        return {
          group_id,
          op_id,
          area_id,
          unit_id,
          only_type,
          include_third_party,
          include_unassignable,
          q: params.term, // search term / user input
        };
      },
      processResults: function (data, params) {
        let values = this.$element.select2("data").map((a) => a.id);
        data = data.map((a) => {
          return { ...a, values };
        });
        return {
          results: data,
        };
      },
      cache: true,
    },
    templateResult: formatResourcesResult,
    templateSelection: formatResourceSelection,
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  if (operation_id === undefined) {
    opts.minimumInputLength = 2;
    opts.placeholder = I18n.t("application.please_type_to_search");
  }
  return opts;
}

function addNameToSelectList(item) {
  $("#group_group_leader_id option[value='" + item.id + "']").each(function () {
    $(this).remove();
  });
  $("#group_group_leader_id").append(
    "<option id=" +
      item.id +
      " value=" +
      item.id +
      " telephone='" +
      item.phone_number +
      "' call_sign='" +
      (item.unit ? item.unit.call_sign : "") +
      "' full_name='" +
      item.name +
      "'>" +
      item.name.trim() +
      "</option> "
  );
}
export const formatResourceSelection = (resource) => {
  if (
    resource != undefined &&
    resource.id != undefined &&
    resource.name != undefined
  ) {
    addNameToSelectList(resource);
    return resource.name || resource.unit.name;
  } else if (
    resource != undefined &&
    resource.id != undefined &&
    resource.text != undefined
  ) {
    return resource.text;
  }
  return false;
};

export function dropHandler(event, target, container) {
  document.getElementById(target).files = event.dataTransfer.files;
  try {
    var droppedFiles = document.getElementById(target).files;
    var file_names = [];
    for (var i = 0; i < droppedFiles.length; i++) {
      file_names.push(droppedFiles[i].name);
    }
    $("#" + target)
      .siblings(".custom-file-label")
      .text(file_names.join(", "));
  } catch (error) {
    console.error(error);
  }
  document.getElementById(container).classList.remove("file-drop-hover");
  toastr.success(I18n.t("helpers.files_successfully_attached"));
  event.preventDefault();
}
export function dragOverHandler(event, target) {
  document.getElementById(target).classList.add("file-drop-hover");
  event.preventDefault();
}
export function dragLeaveHandler(event, target) {
  document.getElementById(target).classList.remove("file-drop-hover");
  event.preventDefault();
}
export function formatResourcesResult(resource, container) {
  function checkIfResourceIsSelected(resource) {
    if (resource) {
      return (
        resource.already_in_unit || resource.values.indexOf(resource.id) !== -1
      );
    }
  }
  if (resource.loading) return resource.text;

  //checking to see if the member is a vehicle or not and return a classname for the image
  function peopleOrMachine(resource) {
    if (resource) {
      let logo_url =
        resource.tenant && resource.tenant.include_logo
          ? resource.tenant.include_logo.small ||
            resource.tenant.include_logo.path
          : "";
      let icon_url =
        resource.unit && resource.unit.include_unit_logo
          ? resource.unit.include_unit_logo.small ||
            resource.unit.include_unit_logo.path
          : logo_url;
      return (
        "class=' groups-avatar' style='background-image:url(" + icon_url + ")'"
      );
    }
  }

  // getting rid of any strange data
  if (resource.name === undefined) {
    return;
  }

  //image and name
  var icon_if_device =
    resource.type === "Device" ? "<span class='fa fa-truck'></span> - " : " ";
  var markup =
    `<div class="select2-result-container ${
      checkIfResourceIsSelected(resource)
        ? "resource_already_in_unit disabled"
        : ""
    }"><div ` +
    peopleOrMachine(resource) +
    '></div><h6 class="nomargin">' +
    icon_if_device +
    resource.name +
    (checkIfResourceIsSelected(resource)
      ? ` (${I18n.t("operation.group.resource_already_in_group")})`
      : "") +
    (resource.tenant.abbreviation
      ? `<small class="float-right" title="${resource.tenant.name}"> [${resource.tenant.abbreviation}]</small>`
      : "");
  // dont display meta info if vehicle
  if (resource.type === "Device") {
    markup += "<small>  " + resource.device_info.device_type + "</small></h6>";
  }

  markup +=
    '</h5><div class="row groups-meta small">' +
    (resource.unit != undefined
      ? '<span class="groups-meta-icon fa fa-shield"></span>' +
        resource.unit.name +
        " "
      : "") +
    "</div></div>";
  return markup;
}

export function trashAttachmentClick(attachment_id, object_id, model) {
  swal({
    title: "Ertu viss?",
    text: "Vilt þú eyða þessu viðhengi?",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#5cb85c",
    confirmButtonText: "Fjarlægja",
    cancelButtonText: "Hætta við",
    closeOnConfirm: true,
  }).then(function () {
    deleteAttachment(attachment_id, object_id, model);
    //remove the user on confirm
  });
}

function deleteAttachment(attachment_id, object_id, model) {
  $.ajax({
    url: "/delete_attachment",
    dataType: "html",
    method: "POST",
    async: true,
    data: {
      model: model,
      attachment_id: attachment_id,
      object_id: object_id,
    },
    success: function (response) {
      $(".attachment_row_" + attachment_id).remove();
      toastr.success("Successfully removed attachment");
    },
    error: function () {},
  });
}
